import React, { useMemo } from 'react'
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from '../Icon'
import { Icon as SIcon } from '../Icon/styles'

const DownloadButtonContent = styled.span`
  display: flex;
  flex-direction: column;
`
const DownloadButtonTitle = styled.span`
  font-weight: bold;
`
const DownloadButtonInfo = styled.span``
const DownloadButtonIconWrap = styled.span`
  margin: auto 2rem auto 0;
  color: ${props => props.theme.palette.primary};
  
  ${SIcon} {
    width: 32px;
    height: 32px;
  }
`

const DownloadButton = styled.a`
  display: inline-flex;
  
  align-items: center;
  text-decoration: none;
  color: #000;
`

const FileDownload = ({ link, type, size, children }) => {
  const humanSize = useMemo(() => {
    if (!size) {
      return
    }

    const base = 1024;
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitIndex = Math.floor(Math.log(size) / Math.log(base));
    return `${Math.round(size / Math.pow(base, unitIndex))} ${units[unitIndex]}`;
  }, [size])

  return <DownloadButton href={link}>
    <DownloadButtonIconWrap aria-hidden={true}>
      <Icon name="download" />
    </DownloadButtonIconWrap>
    <DownloadButtonContent>
      <DownloadButtonTitle>{children}</DownloadButtonTitle>
      {!!humanSize && !!type && (<DownloadButtonInfo>
        ({type.toUpperCase()}, {humanSize})
      </DownloadButtonInfo>)}
    </DownloadButtonContent>
  </DownloadButton>
}

FileDownload.propTypes = {
  link: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.number,
}
FileDownload.defaultProps = {
  type: undefined,
  size: undefined,
}

export default FileDownload
